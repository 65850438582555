import React from "react";

const IncentiveCard = ({ incentive, handleShow }) => {
  const renderLogo = () => {
    const logo = incentive.sponsoring_organization?.logo;
    if (!logo) {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#6A7894",
          }}
        />
      );
    }

    return (
      <img
        src={logo}
        alt={incentive.sponsoring_organization.name}
        className="organization-logo"
        onError={(e) => {
          e.target.onerror = null;
          // Replace with gray circle on error
          e.target.style.display = "none";
          e.target.parentElement.innerHTML = `<div style="width: 40px; height: 40px; border-radius: 50%; background-color: #6A7894"></div>`;
        }}
      />
    );
  };

  return (
    <div className="incentive-card">
      <div className="incentive-card-header">
        <div className="organization-badge">{renderLogo()}</div>
        <div className="incentive-type-badge">
          {incentive.incentive_type?.name || "Other"}
        </div>
      </div>
      <h5>{incentive.name}</h5>
      <p className="incentive-description">{incentive.short_description}</p>
      <div className="incentive-footer">
        <span className="incentive-organization">
          {incentive.sponsoring_organization?.name || "Not Specified"}
        </span>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => handleShow(incentive)}
        >
          Details
        </button>
      </div>
    </div>
  );
};

const IncentiveList = ({ incentives, handleShow }) => {
  if (!incentives || incentives.length === 0) {
    return <div className="no-incentives">No incentives found</div>;
  }

  return (
    <div className="incentive-list">
      {incentives.map((incentive) => (
        <div key={incentive.id} className="incentive-card-wrapper">
          <IncentiveCard incentive={incentive} handleShow={handleShow} />
        </div>
      ))}
    </div>
  );
};

export default IncentiveList;
